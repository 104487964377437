.inputdiv {
    margin-top: 10px;
    margin-bottom: 0;
}

.success,
.success input {
    color: #468847;
    background-color: #DFF0D8;
}

.error,
.error input {
    color: #B94A48;
    background-color: #F2DEDE;
}

.error::before {
    display: inline;
    content: "⚠ ";
}

.list {
    margin: 2px 0 3px;
    padding: 0;
    list-style-type: none;
    font-size: 0.9em;
    line-height: 1.1em;
    color: #B94A48;
    opacity: 1;
    transition: all .3s ease-in;
    -o-transition: all .3s ease-in;
    -moz-transition: all .3s ease-in;
    -webkit-transition: all .3s ease-in;
    max-width: 350px;
}

.errors-list.filled {
    opacity: 1;
}

#ulErrorList {
    color: #B94A48;
}

#ulErrorList li {
    color: #B94A48;
}

.bsCalloutWarning {
    /*background-color: #fcf8f2;*/
    border-color: #B94A48 /*#f0ad4e*/;
}

.bsCalloutWarning h4, .bsCalloutWarning p.err {
    color: #B94A48 /*#f0ad4e*/;
}

.bsCallout h4 {
    margin-top: 0;
    margin-bottom: 5px;
}
